<template>
  <div
    class="container"
    :style="{
      background: loading ? '#ffffff' : '#fbf7f3',
    }"
  >
    <!-- 人谱头像 -->
    <van-skeleton avatar :loading="loading" class="avatar u-flex u-row-center">
      <van-image
        class="avatar-image"
        width="1.9rem"
        round
        :src="character.roleImg"
        fit="cover"
      />
    </van-skeleton>

    <!-- 人谱关键词 or 人普特性语 -->
    <van-skeleton title row="1" :loading="loading" class="keywords">
      <h3 class="keywords-name u-text-center">{{ character.role }}</h3>
      <span class="keywords-content u-text-center">
        {{ character.keyword }}
      </span>
    </van-skeleton>

    <!-- 人谱特性 -->
    <van-skeleton title :loading="loading" class="characteristics">
      <div class="characteristics-info">
        <h4 class="characteristics-name">{{ $t("humanSpectrum") }}</h4>
        <p class="characteristics-content">
          {{ character.character }}
        </p>
      </div>
    </van-skeleton>

    <!-- 人谱信息 -->
    <van-skeleton title row="1" :loading="loading" class="genealogy">
      <div class="genealogy-info u-flex u-row-between">
        <div class="genealogy-item genealogy-left u-flex-1 u-text-center">
          <h4 class="u-text-left">{{ $t("humanSpectrumScore") }}</h4>
          <van-circle
            :value="character.score"
            :color="gradientColor"
            class="circle"
            size="2rem"
            stroke-width="80"
            layer-color="#F4EFE7"
          >
            <div slot="default" class="fraction u-flex u-row-center">
              <p class="fraction-num">{{ character.score }}</p>
            </div>
          </van-circle>
        </div>
      </div>
    </van-skeleton>

    <!-- 宜用颜色 -->
    <van-skeleton title row="0" :loading="loading" class="suitable">
      <div class="suitable-info">
        <h4 class="suitable-name">{{ $t("suitableColor") }}</h4>
        <ul class="u-flex suitable-color u-text-center">
          <li
            v-for="item in character.yiColourValue"
            :key="item"
            class="u-flex-1"
          >
            <span class="round" :style="{ background: item }" />
          </li>
        </ul>
      </div>
    </van-skeleton>

    <!-- 弹窗 -->
    <AlertProp
      :title="propType ? $t('detailedWish') : $t('whatGenealogy')"
      :style="{
        height: propType ? 'auto' : '70%',
        overflow: propType ? 'auto' : 'hidden',
      }"
      :showProp="show"
      @cancel="closeProp"
    >
      <ul class="prop-content">
        <!-- 星體詳解 -->
        <template v-if="propType">
          <li class="prop-content-li" v-html="$t('detailedWishTip')"></li>
        </template>
        <!-- 什么是人谱 -->
        <template v-else>
          <li class="prop-content-li">
            <p style="white-space: pre-line">
              {{ $t(`humanSpectrumTip`) }}
            </p>
          </li>
        </template>
      </ul>
    </AlertProp>
  </div>
</template>

<script>
import AlertProp from "@/components/AlertProp";
import { getCharacter } from "@/api/character";

export default {
  name: "myProfile",
  components: {
    AlertProp,
  },
  data() {
    return {
      character: {},
      // 是否展示骨架屏
      loading: false,

      // 人谱圆环的渐变色
      gradientColor: {
        "0%": "#A81111",
        "100%": "#FA965F",
      },

      // 手风琴绑定的集合
      activeNames: [],

      // 是否展示弹窗
      show: false,

      // 弹窗的title
      title: "",

      // 弹窗的类型（0-人谱注释弹窗 / 1-星运详解）
      propType: 0,
      characters: [],
    };
  },
  created() {
    const id = this.$route.params.id;
    if (!id) return;
    this.getCharacter(id);
  },
  methods: {
    // 获取人谱信息
    getCharacter(id) {
      getCharacter(id).then((response) => {
        if (response.code === 200) {
          this.character = response.data;
        }
      });
    },
    // 打开弹窗
    handleShowProp(type) {
      this.propType = type;
      this.show = true;
    },

    // 关闭弹窗
    closeProp(val) {
      this.show = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0.36rem 0.4rem;
}

.avatar-image {
  display: block;
  margin: 0.8rem auto 0.5rem;
}

.avatar {
  .van-skeleton__avatar {
    margin: 0.8rem auto 0.5rem;
  }

  .van-skeleton__content {
    width: auto;
  }
}

.keywords {
  .van-skeleton__title {
    width: 30%;
    height: 0.66rem;
    margin: 0 auto;
  }

  .van-skeleton__row {
    width: 50% !important;
    margin: 0.16rem auto;
  }
}

.keywords-name {
  font-size: 0.48rem;
  font-weight: 500;
  color: #995614;
}

.keywords-content {
  display: block;
  font-size: 0.28rem;
  color: #9d8760;
}

.characteristics {
  .van-skeleton__title {
    width: 100%;
    height: 1.4rem;
    margin: 0.48rem auto 0.4rem;
  }
}

.characteristics-info {
  background: #f4efe7;
  border-radius: 0.08rem;
  margin: 0.48rem auto 0.4rem;
  font-size: 0.28rem;
  color: #988157;
  padding: 0.3rem 0.34rem 0.48rem;
  box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.18);

  .characteristics-name {
    margin-bottom: 0.36rem;
  }
}

.genealogy {
  .van-skeleton__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .van-skeleton__title,
    .van-skeleton__row {
      flex: 1;
      height: 1.4rem;
    }

    .van-skeleton__row {
      margin: 0 0 0 0.3rem;
    }
  }
}

.genealogy-info {
  border-radius: 0.08rem;
  font-size: 0.28rem;
  font-weight: 500;
  color: #ac863c;

  .genealogy-item {
    padding: 0.3rem 0.3rem 0;
    height: 3.48rem;
    background: #f4efe7;
    box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.18);
  }

  .genealogy-left {
    .circle {
      margin-top: 0.3rem;
    }

    /deep/ {
      .van-circle svg {
        transform: rotate(180deg);
      }
    }

    .fraction {
      height: 100%;
      border-radius: 50%;
      background: #f0e7d4;

      .fraction-num {
        width: 1.2rem;
        height: 1.2rem;
        line-height: 1.2rem;
        background: #fcf8f3;
        color: #f67731;
        font-size: 0.5rem;
        border-radius: 50%;
        box-shadow: 0 0.06rem 0.28rem 0 #d5c5b4,
          0 0.16rem 0.16rem 0 #ffffff inset,
          -0.06rem -0.1rem 0.16rem 0 #ece0d5 inset;
      }
    }
  }

  .what-genealogy {
    height: calc(100% - 0.28rem);
    margin: 0.24rem -0.3rem 0;
    padding-bottom: 0.46rem;
  }
}

.suitable {
  .van-skeleton__title {
    width: 100%;
    height: 1.4rem;
    margin: 0.48rem auto 0;
  }
}

.suitable-name {
  margin-bottom: 0.36rem;
}

.suitable-info {
  padding: 0.3rem 0.3rem 0.6rem;
  background: #f4efe7;
  box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.18);
  font-size: 0.28rem;
  color: #988157;
  margin: 0.48rem auto 0.4rem;

  .suitable-color {
    .round {
      display: inline-block;
      width: 0.72rem;
      height: 0.72rem;
      border-radius: 50%;
      background: pink;
    }
  }
}

.guess-know {
  .van-skeleton__title {
    width: 100%;
    height: 1.4rem;
    margin: 0.48rem auto;
  }
}

.guess-know-info {
  padding: 0 0.3rem 0.1rem;
  margin-bottom: 0.4rem;
  background: #f4efe7;
  box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.18);
  font-size: 0.28rem;
  color: #988157;

  /deep/ {
    .van-cell {
      background: #f4efe7;
      padding: 0.3rem 0 0.3rem;
    }

    .van-cell__right-icon {
      margin-left: 0;
      color: #c3b494;
    }

    .van-collapse-item__content {
      background: #f4efe7;
      padding: 0;
      word-break: break-all;
      text-align: justify;
    }
  }

  .guess-know-name {
    .van-cell__left-icon {
      display: flex;
      align-items: center;
    }
    .van-cell__title {
      font-weight: 600;
      color: #988157;
      font-size: 0.28rem;
    }

    .van-cell__value {
      color: #c3b494;
      font-size: 0.24rem;
    }
  }

  .guess-know-ul {
    margin-top: 0.22rem;

    .guess-know-li {
      margin-bottom: 0.28rem;
    }

    .guess-know-h3 {
      margin-left: 0.12rem;
      font-size: 0.32rem;
      font-weight: 600;
      color: #6b4e16;
    }

    /deep/ {
      .van-cell {
        padding: 0.15rem 0 0.35rem;
        color: #988157;
        font-size: 0.32rem;
      }

      .van-cell__right-icon {
        margin-left: 0;
        color: #988157;
      }

      .van-collapse-item__title--expanded {
        position: relative;
      }

      .van-collapse-item__title--expanded::before {
        content: " ";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0.5rem;
        border-right: 0.15rem solid transparent;
        border-left: 0.15rem solid transparent;
        border-bottom: 0.18rem solid #efe5d2;
        transition: all 0s;
      }

      .van-collapse-item__wrapper {
        overflow: hidden;
        transition: all 0s;
      }

      .van-collapse-item__content {
        font-size: 0.28rem;
        color: #755a24;
        word-break: break-word;
        text-align: justify;
        background: #efe5d2;
        border-radius: 0.2rem;
        padding: 0.2rem;
      }
    }
  }
}

// 设置弹窗背景
.container-prop {
  background: url("../../assets/images/container_prop_bg.png") no-repeat center
    center;
  background-size: 100%;
}

.prop-content {
  font-size: 0.32rem;
  font-weight: 500;
  color: #6b4e16;
  text-align: justify;
  height: 100%;
  overflow: auto;
}

.prop-content-li {
  margin-top: 0.36rem;

  p {
    text-indent: 0.6rem;
    margin-bottom: 0.4rem;
  }
}

.prop-content-span {
  margin: 0 0.1rem;
}
</style>
